import React from "react"

import CumminsLogo from "../images/logo/Cummins.png"
import VolvoLogo from "../images/logo/Volvo-mecc-alte-logo.png"
import PowershareLogo from "../images/logo/powershare-logo.png"
import MasonsLogo from "../images/logo/masons-logo.png"
import LiebherrLogo from "../images/logo/libherr-logo.png"
import LeroyLogo from "../images/logo/leroy-somer-logo.png"
import MbhLogo from "../images/logo/mbh-logo.jpeg"
import MwmLogo from "../images/logo/mwm-logo.png"

function HomeLogo() {
  return (
    <section id="home-logo" className="bg-amber-300">
      <div
        id="text-logo"
        className="text-primary text-4xl font-semibold px-10 py-12 text-center"
      >
        <h2>برند محصولات ارائه شده توسط شرکت مشارکت نیرو</h2>
      </div>
      <div
        id="image-logo"
        className="grid grid-cols-3 items-center py-10 px-10"
      >
        <img src={CumminsLogo} alt="Cummins Logo" />
        <img src={VolvoLogo} alt="Volvo Logo" />
        <img src={PowershareLogo} alt="Powershare Logo" />
        <img src={MasonsLogo} alt="Masons Logo" />
        <img src={LiebherrLogo} alt="Liebherr Logo" />
        <img src={LeroyLogo} alt="Leroy Logo" />
        <img src={MbhLogo} alt="MBH Logo" />
        <img src={MwmLogo} alt="MWM Logo" />
      </div>
    </section>
  )
}

export default HomeLogo
