import React from "react"

import electricityTransmition from "../images/electricty-transmition-photo.jpeg"

function HomeAbout() {
  return (
    <section id="ElectricityTransmition" className="bg-amber-300">
      <div
        id="about-section"
        className="text-secondary text-4xl font-semibold px-10 py-4 text-center"
      >
        <h2>
          شرکت مشارکت نیرو پیشرو در زمینه طراحی مهندسی نیروگاههای دیزلی و گازی
          در ایران
        </h2>

        <div
          id="about-details"
          className="grid grid-cols-2 sm:grid-cols-1 items-center  py-4 px-10 sm:px-2"
        >
          <p className="text-lg font-normal text-primary">
            ما از طراحی تا اجرا، تامین لوازم یدکی، سرویس و نگهداری تجهیزات نصب
            شده به صورت دوره ای تماما در کنار شما حضور داریم و از آنجا که برای
            خدمات پس از فروش اولویت ویژه ای قائل هستیم اطمینان می دهیم که بهترین
            راندمان کاری در دراز مدت برای مشتریان فراهم گشته و باعث آسودگی خاطر
            مشتریان خود هستیم.
          </p>
          <div id="ElectrictyTransmition" className="p-10 sm:px-2">
            <img src={electricityTransmition} alt="Electricity transmition" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeAbout
