import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeHero from "../components/home-hero"
import HomeLogo from "../components/home-logo"
import HomeServices from "../components/home-services"
import HomeAbout from "../components/home-about"
import HomeProjects from "../components/home-projects"

const IndexPage = () => (
  <Layout>
    <Seo
      title="شرکت مشارکت نیرو عرضه کننده ژنراتورهای دیزلی و گازی"
      description="شرکت مشارکت نیرو آریا کیش عرضه کننده ژنراتورهای دیزلی و گازی با برندهای Cummins و Volvo و Baudouin"
    />
    <HomeHero />
    <HomeLogo />
    <HomeServices />
    <HomeAbout />
    <HomeProjects />
  </Layout>
)

export default IndexPage
