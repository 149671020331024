import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

function HomeServices() {
  return (
    <section id="services" className="bg-black">
      <div
        id="services-header"
        className=" grid grid-col-1 text-white text-4xl font-semibold px-10 py-12 text-center"
      >
        <h2>ارائه خدمات نیروگاههای دیزلی و گازی</h2>

        <section
          id="services-details"
          className="grid grid-cols-2 sm:grid-cols-1 items-center  py-10 px-10 sm:px-1"
        >
          <div className="flex flex-col items-center space-y-6 p-10">
            <h3 className="text-4xl font-medium text-white">
              طراحی مهندسی نیروگاههای دیزلی و گازی
            </h3>
            <p className="text-lg font-normal text-white">
              پیاده سازی نیروگاه دیزلی یا گازی امری پیچیده است که طراحی در آن
              حرف اول را میزند. عمده نگرانی مشتریان بعد از اصالت موتورژنراتورها
              و ضمانت تامین قطعات یدکی اصلی برای موتورژنراتورهای دیزلی و گاز
              سوز، نحوه طراحی نیروگاه دیزلی و یا گازی می باشد که از اهمیت ویژه
              ای برخوردار می باشد.
            </p>
            <button
              className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded"
              onClick={() => {
                navigate("/services")
              }}
            >
              ملاحظه خدمات
            </button>
          </div>
          <div id="designGenerators" className="p-10">
            <StaticImage
              src="../images/services/design-diesel-generators.jpg"
              alt="Design Diesel Generators"
            />
          </div>

          <div id="supplyGenerators" className="p-10">
            <StaticImage
              src="../images/services/designing-monitoring-system-of-diesel-generator-set.jpg"
              alt="Supply Diesel Generators"
            />
          </div>
          <div className="flex flex-col items-center space-y-6 p-10">
            <h3 className="text-4xl font-medium text-white">
              تامین موتور ژنراتور، نصب و راه اندازی و بهره برداری
            </h3>
            <p className="text-lg font-normal text-white">
              عمده تمرکز شرکت مشارکت نیرو بر تامین انواع موتورژنراتورهای دیزلی
              از معتبر ترین برندهای جهانی مانند Cummins Power و VOLVO و همچنین
              تامین موتورژنراتورهای گازسوز LIEBHERR می باشد.
            </p>
            <button
              className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded"
              onClick={() => {
                navigate("/services")
              }}
            >
              ملاحظه خدمات
            </button>
          </div>

          <div className="flex flex-col items-center space-y-6 p-10">
            <h3 className="text-4xl font-medium text-white">
              تامین قطعات یدکی و مصرفی ژنراتورها
            </h3>
            <p className="text-lg font-normal text-white">
              قطعات یدکی اصلی متضمن کارکرد پیوسته و بدون نقص می باشند. تامین
              قطعات یدکی و مصرفی یک پروژه بدون در نظر گرفتن توان آن از اهمیت
              بسیار بالایی برخوردار است. اهمیت این موضوع صرفا در تامین قطعات
              یدکی اصلی و تامین آن از سازنده موتورزنراتورها می باشد چرا که تامین
              قطعات غیر اصلی از هر سازنده ای برای همه فروشندگان وجود دارد.
            </p>
            <button
              className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded"
              onClick={() => {
                navigate("/services")
              }}
            >
              ملاحظه خدمات
            </button>
          </div>
          <div id="GeneratorSpareParts" className="p-10">
            <StaticImage
              src="../images/services/generator-spare-parts.jpg"
              alt="Generator Spare Part"
            />
          </div>

          <div id="generatorMaintanaceService" className="p-10">
            <StaticImage
              src="../images/services/generator-maintenance-service.jpg"
              alt="Generator Maintanace Service"
            />
          </div>
          <div className="flex flex-col items-center space-y-6 p-10">
            <h3 className="text-4xl font-medium text-white">
              انجام سرویسهای دوره ای ژنراتورها
            </h3>
            <p className="text-lg font-normal text-white">
              سرویسهای دوره ای یک موتورژنراتور و یا یک نیروگاه از مهم ترین عوامل
              در میزان تولید برق پایدار و با کیفیت می باشد. استفاده از قطعات
              اصلی و تعویض این قطعات در زمان های اعلام شده می تواند خروجی پایدار
              یک موتورژنراتور و یا یک نیروگاه را تضمین نماید.
            </p>
            <button
              className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded"
              onClick={() => {
                navigate("/services")
              }}
            >
              ملاحظه خدمات
            </button>
          </div>
        </section>
      </div>
    </section>
  )
}

export default HomeServices
