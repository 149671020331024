import React from "react"
import { StaticImage } from "gatsby-plugin-image"

function HomeProjects() {
  return (
    <section id="projects" className="bg-black">
      <div
        id="projects-header"
        className=" grid grid-col-1 text-white text-4xl font-medium px-10 py-12 text-center"
      >
        <h2>برخی از پروژه های انجام شده</h2>

        <div
          id="projects-details"
          className="grid grid-cols-3 sm:grid-cols-1 items-center gap-10 p-10 sm:px-1 sm:py-10 text-lg"
        >
          <div
            id="shirin-project"
            className="border border-secondary p-5 sm:px-2 sm:mx-2 rounded shadow-lg shadow-gray-100 transform hover:scale-105"
          >
            <h3 className="text-center -mt-9 text-white bg-secondary border border-secondary rounded font-bold text-xl shadow-lg shadow-gray-100">
              پروژه مجتمع شیرین
            </h3>
            <StaticImage
              src="../images/projects/Shirin Project.jpeg"
              alt="Shirin Project"
              className="px-5 py-5"
            />
            <h4 className="text-2xl">
              پروژه تامین برق اضطراری مجتمع تجاری و اداری
            </h4>
            <br />
            <ul className="text-right">
              <li>ظرفیت : 0.8 مگاوات</li>
              <li>محل پروژه : تهران</li>
              <li>نوع مولد : Volvo-MeccAlte (Silent)</li>
              <li>نوع پروژه : تامین برق به صورت EPC</li>
            </ul>
          </div>

          <div
            id="hurmuz-project"
            className="border border-secondary p-5 rounded shadow-lg shadow-gray-100 transform hover:scale-105"
          >
            <h3 className="text-center -mt-9 text-white bg-secondary border border-secondary rounded font-bold text-xl shadow-lg shadow-gray-100">
              پروژه جزیره هرمز
            </h3>
            <StaticImage
              src="../images/projects/Hurmuz Project.jpeg"
              alt="Hurmuz Project"
              className="px-5 py-5"
            />
            <h4 className="text-2xl">
              پروژه تامین برق جزیره هرمز (در دست اجرا)
            </h4>
            <br />
            <ul className="text-right">
              <li>ظرفیت : 11.5 مگاوات</li>
              <li>محل : جزیره هرمز</li>
              <li>نوع مولد : Mitsubishi 18KU30A</li>
              <li>نوع پروژه :تامین برق بصورت EPC</li>
            </ul>
          </div>

          <div
            id="abumusa-project"
            className="border border-secondary p-5 rounded shadow-lg shadow-gray-100 transform hover:scale-105"
          >
            <h3 className="text-center -mt-9 text-white bg-secondary border border-secondary rounded font-bold text-xl shadow-lg shadow-gray-100">
              پروژه جزیره ابوموسی
            </h3>
            <StaticImage
              src="../images/projects/Abumusa Project.jpeg"
              alt="Abumusa Project"
              className="px-5 py-5"
            />
            <h4 className="text-2xl">
              پروژه تامین برق جزیره ابوموسی در خلیج فارس
            </h4>
            <br />
            <ul className="text-right">
              <li>ظرفیت : 10 مگاوات</li>
              <li>محل : جزیره ابوموسی</li>
              <li>نوع مولد : Cummins Power KTA50 (Silent)</li>
              <li>نوع پروژه : تامین برق به صورت EPC</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeProjects
