import React from "react"
import { navigate } from "gatsby"
import { Player } from "@lottiefiles/react-lottie-player"

function HomeHero() {
  return (
    <>
      <h1 className="text-center font-bold p-2 text-3xl">
        شرکت مشارکت نیرو عرضه کننده ژنراتورهای دیزل و گازی
      </h1>
      <section
        id="main-hero"
        className="grid grid-cols-2 sm:grid-cols-1 items-center py-0 sm:py-10"
      >
        <div
          id="hero-animation"
          className="border rounded-md px-2 mx-5 shadow-lg shadow-gray-300"
        >
          <Player
            autoplay
            loop
            src="https://assets1.lottiefiles.com/packages/lf20_YDezq8.json"
          ></Player>
        </div>
        <div className="flex flex-col items-center space-y-6">
          <h2 className="text-6xl sm:text-4xl font-semibold text-primary">
            ژنراتور دیزلی یا گازی؟
          </h2>
          <h3 className="text-2xl sm:text-xl font-semibold text-primary">
            شرکت مشارکت نیرو هر دو را ارائه می نماید
          </h3>
          <button
            className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded shadow-lg shadow-black hover:bg-primary"
            onClick={() => {
              navigate("/diesel-generators")
            }}
          >
            ملاحظه محصولات
          </button>
        </div>
      </section>
    </>
  )
}

export default HomeHero
